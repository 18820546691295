<template>
  <div class="home-wddd">
    <div class="box">
      <a-tabs default-active-key="1" @change="tabFun">
        <a-tab-pane key="1" :tab="$t('pd.wddd.all')">
          <div class="box_all" v-if="list.length>0">
            <div class="exchange">
              <!-- <img src="../../assets/image/exchange.png" alt="">
                    <span>使用兑换券</span> -->
            </div>
            <div class="order_information">
              <div class="order_infor" @click="test1">订单信息</div>
              <div class="order_money">支付金额</div>
              <div class="order_time">支付时间</div>
              <div class="order_period">有效期</div>
              <div class="order_status">有效状态</div>
            </div>
            <div id="components-layout-demo-basic">
              <a-layout v-for="(item,index) in list" :key="index">
                <a-layout-header style="justify-content: space-between;">
                  <div style="display: flex;">
                    <div class="time">{{format.format(item.createDate)}}</div>
                    <div class="danhao">订单号：{{item.outTradeNo}}</div>
                  </div>
                  <a-statistic-countdown v-if="item.paymentStatus===0"
                    title="剩余支付时间："
                    :value="item.paymentExpireTime"
                    @finish="onFinish(item)"
                  />
                </a-layout-header>
                <a-layout-content>
                  <div class="content">
                    <div class="content_img">
                      <img :src="resBasePath+'/'+item.thumbnailImagePath" alt="">
                    </div>
                    <a-tooltip placement="" :title="item.greatClassRoomCourseName">
                      <div class="book">{{item.greatClassRoomCourseName}}</div>
                    </a-tooltip>
                    <div class="money">￥{{item.orderAmount}}</div>
                    <div class="time">{{format.formatDayTime(item.createDate)}}</div>
                    <div class="time_status">{{format.formatDayTime(item.expireIn)}}</div>
                    <div class="obligation">{{format.payStatus(item.paymentStatus)}}</div>
                    <div class="ensure">
                      <div v-if="item.paymentStatus!==1&&item.paymentStatus!==3">
                        <div class="immediate_payment" @click="payNow(item)">立即付款</div>
                        <div class="cancel_order" @click="cancelOrder(item)">取消订单</div>
                      </div>
                    </div>
                  </div>
                </a-layout-content>
              </a-layout>
            </div>
          </div>
          <div class="slow_network" v-if="slow_network">
            <div clas="slown_etwork_img">
              <img src="../../assets/image/jiazai.png" alt="">
            </div>
            <div class="explain_text">网络不给力哦～</div>
            <div class="refresh">
              <span>刷新试试</span>
            </div>
          </div>
          <div class="slow_network" v-if="list.length==0">
            <div clas="slown_etwork_img">
              <img src="../../assets/image/jiazai.png" alt="">
            </div>
            <div class="explain_text">{{$t('pd.wddd.noOrder')}}</div>
            <!-- <div class="refresh">
                  <span>去下单</span>
                </div> -->
          </div>
        </a-tab-pane>
        <a-tab-pane key="2" :tab="$t('pd.wddd.paid')" force-render>
          <div class="box_all" v-if="list.length>0">
            <div class="exchange">
              <!-- <img src="../../assets/image/exchange.png" alt="">
                    <span>使用兑换券</span> -->
            </div>
            <div class="order_information">
              <div class="order_infor" @click="test1">订单信息</div>
              <div class="order_money">支付金额</div>
              <div class="order_time">支付时间</div>
              <div class="order_period">有效期</div>
              <div class="order_status">有效状态</div>
            </div>
            <div id="components-layout-demo-basic">
              <a-layout v-for="(item,index) in list" :key="index">
                <a-layout-header>
                  <div class="time">{{format.format(item.createDate)}}</div>
                  <div class="danhao">订单号：{{item.outTradeNo}}</div>
                </a-layout-header>
                <a-layout-content>
                  <div class="content">
                    <div class="content_img">
                      <img :src="resBasePath+'/'+item.thumbnailImagePath" alt="">
                    </div>
                    <a-tooltip placement="" :title="item.greatClassRoomCourseName">
                      <div class="book">{{item.greatClassRoomCourseName}}</div>
                    </a-tooltip>
                    <div class="money">￥{{item.orderAmount}}</div>
                    <div class="time">{{format.formatDayTime(item.createDate)}}</div>
                    <div class="time_status">{{format.formatDayTime(item.expireIn)}}</div>
                    <div class="obligation">{{format.payStatus(item.paymentStatus)}}</div>
                    <div class="ensure">
                      <div v-if="item.paymentStatus!==1&&item.paymentStatus!==3">
                        <div class="immediate_payment" @click="payNow(item)">立即付款</div>
                        <div class="cancel_order" @click="cancelOrder(item)">取消订单</div>
                      </div>
                    </div>
                  </div>
                </a-layout-content>
              </a-layout>
            </div>
          </div>
          <div class="slow_network" v-if="slow_network">
            <div clas="slown_etwork_img">
              <img src="../../assets/image/jiazai.png" alt="">
            </div>
            <div class="explain_text">网络不给力哦～</div>
            <div class="refresh">
              <span>刷新试试</span>
            </div>
          </div>
          <div class="slow_network" v-if="list.length==0">
            <div clas="slown_etwork_img">
              <img src="../../assets/image/jiazai.png" alt="">
            </div>
            <div class="explain_text">{{$t('pd.wddd.noOrder')}}</div>
            <!-- <div class="refresh">
                  <span>去下单</span>
                </div> -->
          </div>
        </a-tab-pane>
        <a-tab-pane key="3" :tab="$t('pd.wddd.pending')">
          <div class="box_all" v-if="list.length>0">
            <div class="exchange">
              <!-- <img src="../../assets/image/exchange.png" alt="">
                    <span>使用兑换券</span> -->
            </div>
            <div class="order_information">
              <div class="order_infor" @click="test1">订单信息</div>
              <div class="order_money">支付金额</div>
              <div class="order_time">支付时间</div>
              <div class="order_period">有效期</div>
              <div class="order_status">有效状态</div>
            </div>
            <div id="components-layout-demo-basic">
              <a-layout v-for="(item,index) in list" :key="index">
                <a-layout-header style="justify-content: space-between;">
                   <div style="display: flex;">
                    <div class="time">{{format.format(item.createDate)}}</div>
                    <div class="danhao">订单号：{{item.outTradeNo}}</div>
                  </div>
                  <a-statistic-countdown v-if="item.paymentStatus===0"
                    title="剩余支付时间："
                    :value="item.paymentExpireTime"
                    @finish="onFinish(item)"
                  />
                </a-layout-header>
                <a-layout-content>
                  <div class="content">
                    <div class="content_img">
                      <img :src="resBasePath+'/'+item.thumbnailImagePath" alt="">
                    </div>
                    <a-tooltip placement="" :title="item.greatClassRoomCourseName">
                      <div class="book">{{item.greatClassRoomCourseName}}</div>
                    </a-tooltip>
                    <div class="money">￥{{item.orderAmount}}</div>
                    <div class="time">{{format.formatDayTime(item.createDate)}}</div>
                    <div class="time_status">{{format.formatDayTime(item.expireIn)}}</div>
                    <div class="obligation">{{format.payStatus(item.paymentStatus)}}</div>
                    <div class="ensure">
                      <div v-if="item.paymentStatus!==1&&item.paymentStatus!==3">
                        <div class="immediate_payment" @click="payNow(item)">立即付款</div>
                        <div class="cancel_order" @click="cancelOrder(item)">取消订单</div>
                      </div>
                    </div>
                  </div>
                </a-layout-content>
              </a-layout>
            </div>
          </div>
          <div class="slow_network" v-if="slow_network">
            <div clas="slown_etwork_img">
              <img src="../../assets/image/jiazai.png" alt="">
            </div>
            <div class="explain_text">网络不给力哦～</div>
            <div class="refresh">
              <span>刷新试试</span>
            </div>
          </div>
          <div class="slow_network" v-if="list.length==0">
            <div clas="slown_etwork_img">
              <img src="../../assets/image/jiazai.png" alt="">
            </div>
            <div class="explain_text">{{$t('pd.wddd.noOrder')}}</div>
            <!-- <div class="refresh">
                  <span>去下单</span>
                </div> -->
          </div>
        </a-tab-pane>
        <a-tab-pane key="4" :tab="$t('pd.wddd.canceled')">
          <div class="box_all" v-if="list.length>0">
            <div class="exchange">
              <!-- <img src="../../assets/image/exchange.png" alt="">
                    <span>使用兑换券</span> -->
            </div>
            <div class="order_information">
              <div class="order_infor" @click="test1">订单信息</div>
              <div class="order_money">支付金额</div>
              <div class="order_time">支付时间</div>
              <div class="order_period">有效期</div>
              <div class="order_status">有效状态</div>
            </div>
            <div id="components-layout-demo-basic">
              <a-layout v-for="(item,index) in list" :key="index">
                <a-layout-header>
                  <div class="time">{{format.format(item.createDate)}}</div>
                  <div class="danhao">订单号：{{item.outTradeNo}}</div>
                </a-layout-header>
                <a-layout-content>
                  <div class="content">
                    <div class="content_img">
                      <img :src="resBasePath+'/'+item.thumbnailImagePath" alt="">
                    </div>
                    <a-tooltip placement="" :title="item.greatClassRoomCourseName">
                      <div class="book">{{item.greatClassRoomCourseName}}</div>
                    </a-tooltip>
                    <div class="money">￥{{item.orderAmount}}</div>
                    <div class="time">{{format.formatDayTime(item.createDate)}}</div>
                    <div class="time_status">{{format.formatDayTime(item.expireIn)}}</div>
                    <div class="obligation">
                      {{format.payStatus(item.paymentStatus)}}
                    </div>
                    <div class="ensure">
                      <!-- <div v-if="item.paymentStatus!==1">
                            <div class="immediate_payment" @click="payNow(item)">立即付款</div>
                            <div class="cancel_order" @click="cancelOrder(item)">取消订单</div>
                          </div> -->
                    </div>
                  </div>
                </a-layout-content>
              </a-layout>
            </div>
          </div>
          <div class="slow_network" v-if="slow_network">
            <div clas="slown_etwork_img">
              <img src="../../assets/image/jiazai.png" alt="">
            </div>
            <div class="explain_text">网络不给力哦～</div>
            <div class="refresh">
              <span>刷新试试</span>
            </div>
          </div>
          <div class="slow_network" v-if="list.length==0">
            <div clas="slown_etwork_img">
              <img src="../../assets/image/jiazai.png" alt="">
            </div>
            <div class="explain_text">{{$t('pd.wddd.noOrder')}}</div>
            <!-- <div class="refresh">
                  <span>去下单</span>
                </div> -->
          </div>
        </a-tab-pane>
      </a-tabs>
    </div>
    <div style="text-align:center;margin:15px 0px;">
      <a-config-provider>
        <a-pagination show-quick-jumper :default-current="1" :page-size="pageSize" @change="handleListChange"
          show-less-items :total=total />
      </a-config-provider>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
import format from '@/utils/format.js'
export default {
  name: 'wddd',
  data () {
    return {
      format,
      resBasePath: this.$resBasePath,
      list: [],
      slow_network: false,
      pageSize: 5,
      total: 0,
      pageNum: 1,
      paymentStatus: ''
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    payNow (item) {
      sessionStorage.setItem('payMess', JSON.stringify(item))
      this.$store.commit('setpayDataMess', item)
      this.$router.push({ name: 'myOrder' })
    },
    handleListChange (current, pageSize) {
      this.pageNum = current
      this.pageSize = pageSize
      this.getList()
    },
    getList () {
      const param = {
        access_token: this.$store.state.access_token,
        userId: this.$store.state.userInfo.id,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        paymentStatus: this.paymentStatus
      }
      axios({
        url: this.$baseUrl + 'great/official/h5pay/listPageOrder',
        method: 'POST',
        data: param
      }).then(res => {
        if (res && res.data && res.data.resultCode == 0) {
          this.list = res.data.resultData.list || []
          this.total = res.data.resultData.total
        }
      })
    },
    cancelOrder (item) { // great/official/h5pay/cancelCourseOrder 取消订单
      const param = {
        access_token: this.$store.state.access_token,
        userId: this.$store.state.userInfo.id,
        outTreadOrderNo: item.outTradeNo
      }
      axios({
        url: this.$baseUrl + 'great/official/h5pay/cancelCourseOrder',
        method: 'POST',
        data: param
      }).then(res => {
        if (res && res.data && res.resultCode === 0) {
          this.$message.success('取消订单成功')
          this.getList()
        }
      })
    },

    tabFun (key) {
      this.pageNum = 1
      this.pageSize = 5
      if (key == 1) {
        this.paymentStatus = ''
      } else if (key == 2) {
        this.paymentStatus = 1
      } else if (key == 3) {
        this.paymentStatus = 0
      } else if (key == 4) {
        this.paymentStatus = 3
      }
      this.getList()
    },
    test1 () {
      this.$router.push({ name: 'dhkc' })
    },
    onFinish (item) {
      item.paymentStatus = 3
    }
  }
}
</script>
<style scoped lang="less">
.home-wddd{
    border-radius: 10px;
    .box{
      width:931px;
      height: 625px;
      overflow:scroll;
      background: #FFFFFF;
      border-radius: 10px;
      position: relative;
      .exchange{
          position: absolute;
          top:12px;
          right:20px;
          display: flex;
          span{
              display: block;
              margin-left:4px;
              width: 80px;
              height: 18px;
              font-size: 16px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #D02900;
              line-height: 18px;
            }
        }
      .box_all{
        width:890px;
        margin:0 auto;
        .order_information{
          width:890px;
          height:52px;
          display: flex;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 52px;
          .order_infor{
            width: 425px;
          }
          .order_money{
            width: 80px;
          }
          .order_time{
            width: 92px;
          }
          .order_period{
            width: 75px;
            text-align: left;
          }
          .order_status{
            width:128px;
            text-align: center;
          }
        }
      }
      .slow_network{
        width:170px;
        //  height:400px;
        margin:0 auto;
        margin-top: 80px;
        .slown_etwork_img{
          width:160px;
          height:160px;
        }
        .explain_text{
          text-align: center;
          height: 22px;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          line-height: 22px;
          margin-top:20px;
        }
        .refresh{
          width: 84px;
          height: 30px;
          border-radius: 20px;
          border: 1px solid #D02900;
          text-align: center;
          margin:0 auto;
          margin-top:20px;
            span{
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #D02900;
              line-height: 26px;
            }
        }
      }
  }
}
/deep/.ant-layout-header {
    height: 40px;
    line-height: 40px;
    padding:0 0;
}
/deep/.ant-statistic-title{
  margin-bottom: 0;
}
/deep/.ant-statistic-content{
  font-size: 14px;
  font-family: inherit;
}
/deep/.ant-statistic{
  display: flex;
  align-items: center;
}
#components-layout-demo-basic {
  text-align: left ;
}
#components-layout-demo-basic .ant-layout-header{
  background: #FCFAF8;
  display: flex;
  .danhao{
    margin-left:20px;
  }

}

#components-layout-demo-basic .ant-layout-content {
  background: #FFFFFF;
  min-height: 120px;
  line-height: 120px;
  .content{
    display: flex;
    text-align: left;
    .content_img{
      width: 150px;
      height: 72px;
      img{
        width: 128px;
        height: 72px;
      }
    }
    .book{
      width: 360px;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .money{
      width: 158px;
      text-align: center;
    }
    .time{
      width: 130px;
    }
    .time_status{
      width:160px;
    }
    .obligation{
      width: 120px;
      text-align: left;
    }
    .ensure{
      width: 130px;
      text-align: center;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      .immediate_payment{
        width: 92px;
        height: 34px;
        background: #D02900;
        border-radius: 4px;
        color: #FFFFFF;
        line-height: 34px;
        margin-top:30px;
        cursor: pointer;

      }
      .cancel_order{
        width: 92px;
        height: 34px;
        color: #666666;
        line-height: 34px;
        cursor: pointer;
      }

    }

  }

}
#components-layout-demo-basic > .ant-layout {
  margin-bottom: 48px;
}
#components-layout-demo-basic > .ant-layout:last-child {
  margin: 0;
}
</style>
